import React from 'react'
import { InnerData, TLM } from 'utils/type';

interface VaryAntennasProps {
  data: {
    name: string;
    value: InnerData;
    id: string;
    select: TLM;
    annName: string;
  };
}

const VaryAntennas: React.FC<VaryAntennasProps> = ({data}) => {

  const getColor = () => {
    if (data && data.select && data.select.telemetry){
      if (data.select.telemetry.toLowerCase().includes(data.name.toLowerCase())) {
        return "green"; // Green for the selected and ON disk
      }
      else{
        return "rgb(100, 100, 100)";
      }
    }else{
      return "rgb(100, 100, 100)";
    }
  }

  return (
    <div >
      <div>

      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="70px" height="70px" viewBox="-0.5 -0.5 70 70">
          <defs/>
          <g>
            <ellipse cx="35" cy="35" rx="30" ry="30" fill={getColor()}/>
          </g>
        </svg>

        <p>{data.name}</p>

      </div>
    </div>
  )
}

export default VaryAntennas;
