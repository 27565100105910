import { decode } from "@msgpack/msgpack";
import { getTemplateSrv } from "@grafana/runtime";
import copy from 'copy-to-clipboard';
import { InnerData, LimitProp } from "./type";

export const nameToMne = {

  "Time CSAC TOD":"CDH_TIME_CSAC_TOD", /* */
  "MAX FSW Uptime":"cdh_version_uptime", /* */
  "Q7 Uptime":"cdh_version_fc_uptime", /* */
  "Q7 Boot Count": "cdh_version_fc_boot_count", /* */
  "N2 Bottle P": "eps_spoc_power_cl_pt_gn2_bottle_p", /* */
  "Bim Curr": "eps_bim_main_current", /* */
  "Bim Volt": "eps_bim_battery_voltage", /* */
  "Rim Decc Ccd Bad Cnt": "rim_frlite_decc_ccd_bad_cnt", /* */
  "Rim Decc Ccd Good Cnt": "rim_frlite_decc_ccd_good_cnt", /* */

  "Spoc Select":"eps_supervisor_dio_spoc_select", /* */

  "Rim Decc Frame Bad Cnt": "RIM_FRLITE_DSP_DECC_FRAME_BAD_CNT", /* */

  "Rim Ex En": "RIM_FRLITE_EX_ENA", /* */
  "Rim Ex Rng En": "RIM_FRLITE_EX_RNG_ENA", /* */
  "Rim Ex Subc Freq Sel": "RIM_FRLITE_EX_SUBC_FREQ_SEL", /* */
  "Rim Ex Sym Rate": "RIM_FRLITE_EX_SYM_RATE", /* */
  "Rim Frames Sent": "RIM_FRLITE_FRAMES_SENT", /* */
  "Rim RF MCU Err Cnt": "RIM_FRLITE_RF_MCU_ERR_CNT", /* */
  "Rim RF MCU Status": "RIM_FRLITE_RF_MCU_STATUS", /* */
  "Rim Rx Agc level": "RIM_FRLITE_RX_AGC_LEVEL", /* */
  "Rim Rx Carr Amp": "RIM_FRLITE_RX_CARRIER_AMPLITUDE", /* */
  "Rim Rx Carr lock": "RIM_FRLITE_RX_CARRIER_LOCK", /* */
  "Rim Rx Carr Spe": "RIM_FRLITE_RX_CARRIER_SPE", /* */
  "Rim Rx Doc State": "RIM_FRLITE_RX_DOC_STATE", /* */
  "Rim Rx NB Pwr": "RIM_FRLITE_RX_NB_PWR", /* */
  "Rim Rx SubCarr Amp": "RIM_FRLITE_RX_SUBCARRIER_AMPLITUDE", /* */
  "Rim Rx SubCarr Lock": "RIM_FRLITE_RX_SUBCARRIER_LOCK", /* */
  "Rim Rx SubCarr Spe": "RIM_FRLITE_RX_SUBCARRIER_SPE", /* */
  "Rim Rx Sym Rate H": "RIM_FRLITE_RX_SYM_RATE_H", /* */
  "Rim Rx Sym Rate L": "RIM_FRLITE_RX_SYM_RATE_L", /* */
  "Rim Tx WD Count": "RIM_STATE_TX_WATCHDOG_COUNT_SECS", /* */
  

  "Hpa Err COunter": "cdh_ant_mgr_enable_hpa_error_counter", /* */
  "Fu Tank2 N T Raw": "tcs_spoc_fu_tank_n_2_t_raw", /* */
  "Ox Tank1 Port T Raw": "tcs_spoc_ox_tank_port_1_t_raw", /* */
  "Solar0 Curr": "EPS_SACM_GROUP_0_SOLAR_PANEL_CURRENT_SENSE", /* */
  "Solar1 Curr": "EPS_SACM_GROUP_1_SOLAR_PANEL_CURRENT_SENSE", /* */
  "Solar2 Curr": "EPS_SACM_GROUP_2_SOLAR_PANEL_CURRENT_SENSE", /* */
  "Solar3 Curr": "EPS_SACM_GROUP_3_SOLAR_PANEL_CURRENT_SENSE", /* */
  "Ox Tank1 S T Raw": "tcs_spoc_ox_tank_s_1_t_raw", /* */
  "Fu Tank1 S T Raw": "tcs_spoc_fu_tank_s_1_t_raw", /* */
  "Ox Tank2 S T Raw": "tcs_spoc_ox_tank_s_2_t_raw", /* */
  "Fu Tank2 S T Raw": "tcs_spoc_fu_tank_s_2_t_raw", /* */
  "Piu T Raw": "tcs_spoc_piu_t_raw", /* */
  "ST B T Raw": "tcs_spoc_st_b_t_raw", /* */
  
  "Ox Tank1 N T Raw": "tcs_spoc_ox_tank_n_1_t_raw", /* */
  "Fu Tank1 N T Raw": "tcs_spoc_fu_tank_n_1_t_raw", /* */
  "Ox Tank2 N T Raw": "tcs_spoc_ox_tank_n_2_t_raw", /* */
  "N2 Bottle T1": "tcs_spoc_n2_bottle_1_t_raw", /* */
  "N2 Bottle T2": "tcs_spoc_n2_bottle_2_t_raw", /* */
  "N2 Bottle T3": "tcs_spoc_n2_bottle_3_t_raw", /* */
  "N2 Bottle T4": "tcs_spoc_n2_bottle_4_t_raw", /* */
  "FSS A T": "aocs_fss_a_celsius_temperature", /* */
  "FSS B T": "aocs_fss_b_celsius_temperature", /* */
  "FSS C T": "aocs_fss_c_celsius_temperature", /* */
  "FSS D T": "aocs_fss_d_celsius_temperature", /* */
  "N2 Bottle Probe T1": "tcs_spoc_rtd_n2_bottle_probe_1_t", /* */
  "N2 Bottle Probe T2": "tcs_spoc_rtd_n2_bottle_probe_2_t", /* */
  "N2 Bottle Probe T3": "tcs_spoc_rtd_n2_bottle_probe_3_t", /* */
  "N2 Bottle Probe T4": "tcs_spoc_rtd_n2_bottle_probe_4_t", /* */
  "EESA T1 Raw": "tcs_supervisor_eesa_1_t_raw", /* */
  "EESA T2 Raw": "tcs_supervisor_eesa_2_t_raw", /* */
  "EIDPU T1 Raw": "tcs_supervisor_eidpu_1_t_raw", /* */
  "EIDPU T2 Raw": "tcs_supervisor_eidpu_2_t_raw", /* */
  "EIDPU Tif Raw": "tcs_supervisor_eidpu_if_t_raw", /* */
  "Radio T Raw": "tcs_supervisor_radiator_radio_t_raw", /* */
  "ST A T Raw": "tcs_supervisor_st_a_t_raw", /* */
  "He Bottle1 T Raw": "tcs_supervisor_he_bottle_1_t_raw", /* */
  "He Bottle2 T Raw": "tcs_supervisor_he_bottle_2_t_raw", /* */
  "Boom Actuator T Raw": "tcs_supervisor_boom_actuator_t_raw", /* */
  
  "Spoc A Volt":"eps_supervisor_power_rail_spoca_vtlm", /* */
  "Spoc B Volt":"eps_supervisor_power_rail_spocb_vtlm", /* */
  "Spoc A Curr":"EPS_SUPERVISOR_POWER_RAIL_SPOCA_CURR_TLM", /* */
  "Spoc B Curr":"EPS_SUPERVISOR_POWER_RAIL_SPOCB_CURR_TLM", /* */

  "BCR Curr":"eps_bim_bcr_current", /* */
  "BCR Volt":"eps_bim_bcr_voltage", /* */
  "BCR Slice A Batt Volt":"eps_bim_buck_bcr_slice_a_battery_voltage_sense", /* */
  "BCR Slice A Charge Curr":"eps_bim_buck_bcr_slice_a_charge_current_sense", /* */
  "BCR Slice A Solar Volt":"eps_bim_buck_bcr_slice_a_solar_voltage_sense", /* */
  "BCR Slice B Batt Volt":"eps_bim_buck_bcr_slice_b_battery_voltage_sense", /* */
  "BCR Slice B Charge Curr":"eps_bim_buck_bcr_slice_b_charge_current_sense", /* */
  "BCR Slice B Solar Volt":"eps_bim_buck_bcr_slice_b_solar_voltage_sense", /* */

  "Rwa Pwr En":"eps_spoc_dio_rw_a_pwr_en", /* */
  "Rwb Pwr En":"eps_spoc_dio_rw_b_pwr_en", /* */
  "Rwc Pwr En":"eps_spoc_dio_rw_c_pwr_en", /* */
  "Rwd Pwr En":"eps_spoc_dio_rw_d_pwr_en", /* */

  "ST A Pwr En":"eps_spoc_dio_st_a_pwr_en", /* */
  "ST B Pwr En":"eps_spoc_dio_st_b_pwr_en", /* */

  "IMUA Pwr En":"eps_spoc_dio_imu_a_pwr_en", /* */
  "IMUB Pwr En":"eps_spoc_dio_imu_b_pwr_en", /* */

  "Spvr Cpu Temp":"cdh_supervisor_cpu_t", /* */
  "Spvr Cpu Usage":"cdh_supervisor_cpu_usage", /* */


  "LGA Selected": "cdh_ant_mgr_lga_pair_select", /*1 or 2 for which LGA should be used*/
  "Ant State": "cdh_ant_mgr_state_machine_state", /* Current state of the antenna selection process */

  "Pip Tl Temp": "apid_0x112.mne.temp_tl_pip", /* ELP top left temperature as reported by the ELP instrument */

  "Plim 28V Curr":"eps_plim_power_rail_sense_28v_iso_i",
  "Plim 28V Volt":"eps_plim_power_rail_sense_28v_iso_v",
  "EESA ErawV":"apid_0x124.mne.erawv",
  "EESA IrawV":"apid_0x124.mne.irawv",
  "Emag Range Mode":"apid_0x119.mne.range_mode",
  "sensor temp":"apid_0x119.mne.sensor_temp",
  "mNLP ll temp":"apid_0x112.mne.temp_ll_mnlp",
  
  "SPOC Boot Cnt": "CDH_SPOC_BOOT_COUNT", /* Current being drawn from the SPOC */
  "spoc monitor tripped": "CDH_SPOC_MONITOR_IS_TRIPPED", /* The amplitude of power int eh carrier tracking. */

  "Supvr Uptime": "cdh_supervisor_uptime", /* Time CPU running since boot */
  "Supvr Boot Ctn": "cdh_supervisor_boot_count", /* number of times processor has been booted */
  "Supvr CountDown Time": "CDH_GND_QUIET_WD_SUPERVISOR_COUNTDOWN_TIME", /* number of times processor has been booted */

  "AGC Level": "comms_spoc_iob_frlite_rx_agc_level", /* Receive channel gain setting. */
  "Spoc Uptime": "cdh_spoc_uptime", /* The time the SPOC has been running since boot. */

  "Burn Seq":"prop_ariane_s_400_engine_main_thruster_burn_sequence_state", /* */
  "Fu Sec I":"eps_piu_power_valve_eng_fu_sec_i", /* */
  "Fu T":"tcs_spoc_eng_fu_t", /* */
  "Ox T":"tcs_spoc_eng_ox_t", /* */

  "Prop TVC Comm Err":"prop_tvc_comms_errors_agg", /* */
  "Prop TVC Comm Fault":"prop_tvc_comms_fault_agg", /* */
  "Prop TVCX Comm Err":"prop_tvc_x_comms_errors_agg", /* */
  "Prop TVCX Comm Fault":"prop_tvc_x_comms_fault_agg", /* */
  "Prop TVCZ Comm Err":"prop_tvc_z_comms_errors_agg", /* */
  "Prop TVCZ Comm Fault":"prop_tvc_z_comms_fault_agg", /* */

  "DiskUsage MMC0 %":"cdh_diskusage_mmc0_used_percent_agg", /* */
  "DiskUsage MMC1 %":"cdh_diskusage_mmc1_used_percent", /* */
  "DiskUsage Spinand0 %":"CDH_DISKUSAGE_SPINAND0_USED_PERCENT", /* */

  "Vehicle Mode Agg":"state_spinand0_last_cmd_vehicle_mode_agg",
  "Vehicle Mode Sh":"state_spinand0_last_cmd_vehicle_mode_sh", /* */

  "FDIR Beacon FPU In Con":"fdir_gnc_beacon_mode_exit_fpu_in_cond_agg", /* */

  "FDIR GNC Quiet Egqwd Exp Fpu In Con":"fdir_cdh_supervisor_egqwd_expiration_fpu_in_cond_agg", /* */
  "FDIR CDH Spvr Uptime":"fdir_cdh_supervisor_uptime_static_fpu_in_cond_agg", /* */

  "FDIR Time Err Unsync Fpu In Con":"fdir_cdh_time_s_unsync_fpu_in_cond_agg", /* */
  "FDIR Time Err Fpu In Con":"fdir_cdh_time_s_err_fpu_in_cond_agg", /* */

  "FDIR EPHEMERIS Est Err":"fdir_gnc_eph_est_err_fpu_in_cond_agg", /* */

  "FDIR SpocA Ocp":"fdir_eps_supervisor_spoca_ocp_sw_fpu_in_cond_agg", /* */
  "FDIR SpocB Ocp":"fdir_eps_supervisor_spocb_ocp_sw_fpu_in_cond_agg", /* */
  "FDIR Spoc MC Ocp":"FDIR_EPS_SPOC_MC_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR SpocA UV1":"fdir_eps_spoc_a_uv1_fpu_in_cond_agg", /* */

  "FDIR Thermal Temp1 Limit":"FDIR_TCS_TEMP_1_LIMIT_FPU_IN_COND_agg", /* */
  "FDIR Thermal Temp2 Limit":"FDIR_TCS_TEMP_2_LIMIT_FPU_IN_COND_agg", /* */

  "FDIR RCS Ontime":"FDIR_GNC_RCS_S_ONTIME_FPU_IN_COND_agg", /* */
  "FDIR RCS Recovery":"FDIR_GNC_RCS_S_RECOVERY_FPU_IN_COND_agg", /* */
  "FDIR RCS Protection":"FDIR_GNC_RCS_S_PROTECTION_FPU_IN_COND_agg", /* */

  "FDIR EPS CL1":"FDIR_EPS_CL_1_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR EPS CL2":"FDIR_EPS_CL_2_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR EPS CL3":"FDIR_EPS_CL_3_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR EPS CL4":"FDIR_EPS_CL_4_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR EPS CL5":"FDIR_EPS_CL_5_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR EPS CL6":"FDIR_EPS_CL_6_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR Comm Hpa":"fdir_eps_supervisor_hpa_ocp_sw_fpu_in_cond_agg", /* */
  "FDIR Comm RfConfig":"fdir_eps_supervisor_rfconfig_ocp_sw_fpu_in_cond_agg", /* */
  "FDIR Comm Lna":"fdir_eps_supervisor_lna_ocp_sw_fpu_in_cond_agg", /* */

  "FDIR StS Ocp":"fdir_eps_st_s_ocp_hw_fpu_in_cond_agg", /* */
  "FDIR StS Soh":"fdir_gnc_st_s_soh_fpu_in_cond_agg", /* */
  "FDIR StB Soh":"fdir_gnc_st_b_soh_fpu_in_cond_agg", /* */
  "FDIR StA Soh":"fdir_gnc_st_a_soh_fpu_in_cond_agg", /* */

  "FDIR RWA OCP":"fdir_eps_rw_a_ocp_hw_fpu_in_cond_agg", /* */
  "FDIR RWB OCP":"fdir_eps_rw_b_ocp_hw_fpu_in_cond_agg", /* */
  "FDIR RWC OCP":"fdir_eps_rw_c_ocp_hw_fpu_in_cond_agg", /* */
  "FDIR RWD OCP":"fdir_eps_rw_d_ocp_hw_fpu_in_cond_agg", /* */
  "FDIR RWA SOH":"fdir_gnc_rw_a_soh_fpu_in_cond_agg", /* */
  "FDIR RWB SOH":"fdir_gnc_rw_b_soh_fpu_in_cond_agg", /* */
  "FDIR RWC SOH":"fdir_gnc_rw_c_soh_fpu_in_cond_agg", /* */
  "FDIR RWD SOH":"fdir_gnc_rw_d_soh_fpu_in_cond_agg", /* */

  "FDIR IMUA OCP":"fdir_eps_imu_a_ocp_hw_fpu_in_cond_agg", /* */
  "FDIR IMUB OCP":"fdir_eps_imu_b_ocp_hw_fpu_in_cond_agg", /* */
  "FDIR IMUA SOH":"fdir_gnc_imu_a_soh_fpu_in_cond_agg", /* */
  "FDIR IMUB SOH":"fdir_gnc_imu_b_soh_fpu_in_cond_agg", /* */

  "FDIR FSS OCP":"fdir_eps_fss_s_ocp_hw_fpu_in_cond_agg", /* */
  "FDIR FSS SOH":"fdir_gnc_fss_s_soh_fpu_in_cond_agg", /* */

  "FDIR Adc mode":"fdir_gnc_adcs_man_mode_fpu_in_cond_agg", /* */
  "FDIR Att Est":"fDIR_GNC_ATT_EST_ERR_FPU_IN_Cond_agg", /* */

  "Htr 1 OCP":"fdir_eps_htr_1_ocp_sw_fpu_in_cond_agg", /* */
  "Htr 2 OCP":"fdir_eps_htr_2_ocp_sw_fpu_in_cond_agg", /* */
  "Htr 3 OCP":"fdir_eps_htr_3_ocp_sw_fpu_in_cond_agg", /* */
  "Htr 4 OCP":"fdir_eps_htr_4_ocp_sw_fpu_in_cond_agg", /* */

  "FDIR VGPO Htr1":"FDIR_EPS_VGPO_HTR_1_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR VGPO Htr2":"FDIR_EPS_VGPO_HTR_2_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR VGPO Htr3":"FDIR_EPS_VGPO_HTR_3_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR VGPO Htr4":"FDIR_EPS_VGPO_HTR_4_OCP_SW_FPU_IN_COND_agg", /* */

  "FDIR SPOC PIU":"FDIR_EPS_SPOC_PIU_OCP_SW_FPU_IN_COND_agg", /* */
  "FDIR PIU TVC":"FDIR_EPS_PIU_TVC_OCP_SW_FPU_IN_COND_agg", /* */

  "Ground-quiet WD Timer":"cdh_gnd_quiet_wd_supervisor_countdown_time", /* */

}

export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){
  if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
    return 'red-limit';
  }
  else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
      return 'yellow-limit';
  }else{
    return 'gray-limit';
  }
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value.toLowerCase() === mne.toLowerCase()) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export const decodeFunc = (data: string) => {

  if (!data || data === "") {
    return null;
  }

  const binaryString = window.atob(data);
        
  // Convert binary string to Uint8Array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  
  // Decode the MessagePack data
  const decodedObject = decode(bytes);
  
  return decodedObject;
}

export type redisDataType = {
  apid: number;
  cnv: string;
  facility: string;
  is_pseudo: boolean;
  mne: string;
  raw: number;
  scid: number;
  t_insert: number;
  t_pkt: number;
  vcid: number;
};

const variableName = 'ScName'; // Replace with your Grafana variable
export const source = getTemplateSrv().replace(`\${${variableName}}`);

export const handleCopyMneName = (mne: string) => {
  copy(mne);
};

export function determineLimit(stringValue: string, limit: LimitProp){

  const value = parseFloat(stringValue);

  if (limit.rl !== null && value < limit.rl){
    return 'rl';
  }else if (limit.rh !== null && value > limit.rh){
    return 'rh';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'rl';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'rh';
  }
  else {
    return "";
  }
}

export const checkOutOfLimitsEx = (data: InnerData) => {
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `rgb(246, 80, 80)`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#CA51EC`;
  }else {
    return `black`;
  }
};

export const checkOutOfLimitsHid = (data: InnerData) => {
  //console.log(data);
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `#d10808`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#A020F0`;
  }
  else {
    return `gray`;
  }
};

