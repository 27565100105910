import React from 'react'

type TextProps = {
    data:{
        input: string;
    }
}

const Text: React.FC<TextProps> = ({ data }) => {
    return (
        <div>
            {data.input}
        </div>
    )
}

export default Text