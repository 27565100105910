import React, { useState } from "react";
import { css, cx } from '@emotion/css';
import PlotlyChart from "./PlotlyChart";
import * as scheme from './Scheme'; // Import your styles
import copy from 'copy-to-clipboard';
import { InnerData, TLM, VisibleTooltips } from "utils/type";
import { checkOutOfLimitsHid, determineColorNew } from "utils/function";
import Spoc from "./Spoc";
import * as exxBoxScheme from './ExxBoxScheme'; // Import your style

interface ExxBoxProp {
  data: {
    name: string;
    value: InnerData;
    exWidth: string;
    oriHeight: string;
    oriWidth: string;
    spocSelect: TLM;
    className: string;
  };
}

const ExxBox: React.FC<ExxBoxProp> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});
  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});
  const [isContentVisible, setIsContentVisible] = useState(false); // State to toggle content visibility

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const handleParentClick = () => {
    if (!isContentVisible) {
      setIsContentVisible(true);
    }
  };

  const handleCollapseClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the parent click
    setIsContentVisible(false);
  };

  // Define styles for the div
  const defaultStyle = css`
    background-color: ${checkOutOfLimitsHid(data.value)}; // Default background color
    width: ${data.oriWidth ? data.oriWidth : '450px'}; // Default width
    height: ${data.oriHeight ? data.oriHeight : '450px'};
    display: flex; 
    justify-content: center;  
    align-items: center;
    cursor: pointer; // To indicate the div is clickable
    transition: all 0.3s ease-in-out; // Add transition for smooth effect

  `;

  const clickedStyle = css`
  background-color: black;
  width: ${data.exWidth ? data.exWidth : '450px'};
    cursor: pointer; // To indicate the div is clickable
    transition: all 0.3s ease-in-out; // Add transition for smooth effect
    border: 5px solid rgb(104, 176, 253);
  `;

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  return (
    <div className={cx(isContentVisible ? clickedStyle : defaultStyle, isContentVisible ? exxBoxScheme[data.className as exxBoxScheme.ClassKey] : '' )} onClick={handleParentClick}>

      <p onClick={handleCollapseClick}>
        {data.name}
        {!isContentVisible && data.spocSelect && data.spocSelect.telemetry && <Spoc spocSelect={data.spocSelect}/>}
      </p>
      {isContentVisible && (
        <div className={scheme.textboxItems}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key}>
              <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}

                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={`${scheme.tbValueSpanContainer} nowheel nodrag`} onClick={() => handleValueChartClick(key)}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData?.live}`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }} />
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ExxBox;
