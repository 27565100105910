import { css } from '@emotion/css';
import React from 'react'
import { Handle, Position } from 'reactflow';

interface BoxProp {
  data: {
    name: string;
    width: number;
    height: number;
  };

}

const Box: React.FC<BoxProp> = ({data}) => {

  const boxContainer = css`
  width: ${data.width}px;
  height: ${data.height}px;
  border: 5px solid black;
`;


  return (
    <div className={boxContainer}>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0, left:200}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0, top:950}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <p className={css`
      font-size: 
      `}>{data.name}</p>
    </div>
  )
}

export default Box;
