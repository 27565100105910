import React, { useEffect, useState, useMemo } from 'react';
import { PanelProps} from '@grafana/data';
import { SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { PanelDataErrorView } from '@grafana/runtime';
import * as dat from 'lil-gui';
import AppNew from './AppNew';
import { Field, LocationData } from 'utils/type';
import { source } from 'utils/function';
import { fetchDataSch, fetchInfluxDB } from 'hooks/DataFetching';
import { usePlayBack } from 'hooks/usePlayback';
import { TimeRangeContext } from 'hooks/TimeRangeContext';
import { DrawerProvider } from 'hooks/DrawerContext';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => ({
  wrapper: css`
    fontFamily: Open Sans;
    position: relative;
  `,
  svg: css`
    position: absolute;
    top: 0;
    left: 0;
  `,
  textBox: css`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
  `,
});

const exampleFields: Field[] = [
  { name: "", type: "", values: []}
];

export const SimplePanel: React.FC<Props> = React.memo(({ options, data, width, height, fieldConfig, id }) => {
  const styles = useStyles2(getStyles);
  const [groundData, setGroundData] = useState<Field[]>([]);
  const [scData, setScData] = useState<LocationData>({ Azimuth: 0, Elevation: 0 });
  const { processedData, setProcessedData, guiValues } = usePlayBack();
  const guiContainerRef = React.useRef<HTMLDivElement>(null);

  // Memoize data processing
  const { selectedData, limitData, oneWayLightTimeFieldValues, diffSouceName } = useMemo(() => {
    const selected = data.series[0] ? data.series[0].fields : exampleFields;
    const limit = data.series[1] ? data.series[1].fields : exampleFields;
    const influxCurrentDelay = data.series[1] ? data.series[1].fields : exampleFields;
    const oneWayLightTimeField = influxCurrentDelay.find(field => field.name === "One-way Light Time [s]");
    const lightTimeValue = oneWayLightTimeField?.values?.[0] ?? 0;
    
    let sourceName = "";
    if (source.toLowerCase().includes("escb")) {
      sourceName = "ESCB";
    } else if (source.toLowerCase().includes("escg")) {
      sourceName = "ESCG";
    }

    return {
      selectedData: selected,
      limitData: limit,
      oneWayLightTimeFieldValues: lightTimeValue,
      diffSouceName: sourceName
    };
  }, [data.series]);

  // Memoize queries
  const queries = useMemo(() => ({
    exQuery: `SELECT station, scName, tAos, tLos, trCode 
      FROM bdps.operational_schedule 
      WHERE scName LIKE '${diffSouceName}' AND tLos > UNIX_TIMESTAMP()
      ORDER BY tLos ASC 
      LIMIT 1;`,
    influxQuery: `from(bucket: "fd_30_days")
      |> range(start: -15d)
      |> filter(fn: (r) => r._measurement == "ESCAPADE")
      |> filter(fn: (r) => r.Spacecraft == "${diffSouceName}")
      |> filter(fn: (r) => r.Station == "DSS 34")
      |> last()`
  }), [diffSouceName]);

  // Fetch data
  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        const [schResult, influxResult] = await Promise.all([
          fetchDataSch(queries.exQuery, 'MySQL - BTAPS - CVT'),
          fetchInfluxDB(queries.influxQuery, 'INFLUX')
        ]);

        if (!mounted) return;

        setGroundData(schResult);

        const azColumn = influxResult.filter((litem: any) => litem.fields[1]?.name === "Azimuth [deg]");
        const elColumn = influxResult.filter((litem: any) => litem.fields[1]?.name === "Elevation [deg]");
        
        const azValue = azColumn[0]?.fields[1].values[0]?.toFixed(2) ?? 0;
        const elValue = elColumn[0]?.fields[1].values[0]?.toFixed(2) ?? 0;

        setScData({
          Azimuth: parseFloat(azValue),
          Elevation: parseFloat(elValue)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [queries]);

  // GUI setup
  useEffect(() => {
    if (!guiContainerRef.current) return;

    const gui = new dat.GUI({ container: guiContainerRef.current });
    gui.close();

    // Setup GUI folders and controllers
    const setupGUIControllers = () => {
      const f1 = gui.addFolder('Query');
      const aggController = f1.add(guiValues.current, 'Agg').name('Agg');
      const aggTimeController = f1.add(guiValues.current, 'aggTime').name('Agg Time');
      const aggFnController = f1.add(guiValues.current.aggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      
      aggFnController.disable();
      aggTimeController.disable();
   
      const lastController = f1.add(guiValues.current, 'Last').name('Last');
      const lastCountController = f1.add(guiValues.current, 'lastCount', 1, 1000, 1).name('Last Count');
      lastCountController.disable();

      // Controller event handlers
      aggController.onChange((value: boolean) => {
        if (value) {
          aggTimeController.enable();
          aggFnController.enable();
        } else {
          aggTimeController.disable();
          aggFnController.disable();
        }
      });

      lastController.onChange((value: boolean) => {
        if (value) {
          lastCountController.enable();
        } else {
          lastCountController.disable();
        }
      });

      const f2 = gui.addFolder('Playback');
      f2.add(guiValues.current, 'startDateTime').name('Start Date Time');
      f2.add(guiValues.current, 'endDateTime').name('End Date Time');
      f2.add(guiValues.current, 'pbaggTime').name('Agg Time');
      f2.add(guiValues.current.pbaggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      f2.add(guiValues.current, 'pbplayBack').name('Playback');
      f2.add(guiValues.current, 'pbstopPlay').name('Stop Playback');

      f1.close();
      f2.close();
    };

    setupGUIControllers();

    if (!guiValues.current.pbisInPlayBack) {
      setProcessedData({});
    }

    return () => {
      gui.destroy();
    };
  }, [guiValues, setProcessedData]);

  const timeRange = useMemo(() => ({
    from: data.timeRange.from,
    to: data.timeRange.to
  }), [data.timeRange]);

  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  return (
    <div className={cx(
      styles.wrapper,
      css`
        width: ${width}px;
        height: ${height}px;
        .lil-gui.root {
          --background-color: #002b36;
          --text-color: #b2c2c2;
          --title-background-color: #001f27;
          --title-text-color: #b2c2c2;
          --widget-color: #094e5f;
          --hover-color: #0a6277;
          --focus-color: #0b6c84;
          --number-color: #2aa0f2;
          --string-color: #97ad00;
          --folder-indent: 100px;
          --widget-border-radius: 0px;
          --font-size: 11px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1000;
        }
      `
    )}>
      <TimeRangeContext.Provider value={{ timeRange, guiValues, oneWayLightTimeFieldValues }}>
      <DrawerProvider>
        <div ref={guiContainerRef} />
        <AppNew 
          dbData={selectedData} 
          width={width} 
          height={height} 
          groundData={groundData} 
          locationData={scData} 
          influxData={processedData} 
          limitData={limitData}
        />
        </DrawerProvider>
      </TimeRangeContext.Provider>
    </div>
  );
});