import React from 'react'
import { TLM } from 'utils/type';

interface SpocProp {
    spocSelect: TLM;
  }

const Spoc: React.FC<SpocProp> = ({spocSelect}) => {

    const getColor = (spocSelect: TLM, self: string) => {
        if (!spocSelect) {
            return;
        }
        if (spocSelect.telemetry.toLowerCase().includes(self.toLowerCase())) {
            return "rgb(72, 200, 44)";
        }
        else{
          return "rgb(100,100,100)";
        }
      }

  return (
    <div>

      <p>Active Spoc</p>

      <div className="">

        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="200px" height="100px" viewBox="-0.5 -0.5 200 100">
          <g>
            <g>
            <rect x="5" y="5" width="90" height="90" fill="rgb(38,38,38)" stroke={getColor(spocSelect, "A")} pointerEvents="all" strokeWidth="6"/>
            </g>
            <g>
              <text x="50" y="60" fill={getColor(spocSelect, "A")} fontFamily="Times New Roman" fontSize="50px" textAnchor="middle">A
              </text>
            </g>
          </g>
        <g>
            <g>
            <rect x="105" y="5" width="90" height="90" fill="rgb(38,38,38)" stroke={getColor(spocSelect, "B")} pointerEvents="all" strokeWidth="6"/>
            </g>
            <g>
              <text x="150" y="60" fill={getColor(spocSelect, "B")} fontFamily="Times New Roman" fontSize="50px" textAnchor="middle">B
              </text>
            </g>
          </g>
        </svg>
            </div>
    </div>
    );
}

export default Spoc;
