import React from 'react';
import { Handle, Position } from 'reactflow';
import { css } from '@emotion/css';
import { TLM } from '../utils/type';

interface PassProp {
  data: {
    carrierLock: TLM;
    bitLock: TLM;
    subCarrierLock: TLM;
  };
}

const PassIndicator: React.FC<PassProp> = ({ data }) => {
  
  const carrierLockFill = data.carrierLock && data.carrierLock.telemetry === '1' ? 'green' : 'gray';
  const bitLockFill = data.bitLock && data.bitLock.telemetry === '1' ? 'green' : 'gray';
  const subCarrierLockFill = data.subCarrierLock && data.subCarrierLock.telemetry === '1' ? 'green' : 'gray';

  const containerStyles = css`
    width: 100%;
  `;

  const rowStyles = css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  `;

  const indicatorStyles = css`
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
      margin: 0 0 10px 0;
    }
  `;


  const indicators = [
    { name: 'Bit-Sync Lock', fill: bitLockFill },
    { name: 'Carrier Lock', fill: carrierLockFill },
    { name: 'SubCarrier Lock', fill: subCarrierLockFill },
  ];

  return (
    <div className={containerStyles}>
      {/* Handles */}
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />

      {/* Indicators */}
      <div className={rowStyles}>
        {indicators.map((indicator, index) => (
          <div key={indicator.name} className={indicatorStyles}>
            <p>{indicator.name}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="300px"
              height="100px"
              viewBox="-0.5 -0.5 300 100"
            >
              <g>
                <ellipse
                  cx="150"
                  cy="50"
                  rx="20"
                  ry="20"
                  fill={indicator.fill}
                  stroke="#FFFFFF"
                  strokeWidth="5"
                  pointerEvents="all"
                />
              </g>
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PassIndicator;
