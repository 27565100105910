import React, { useState } from "react";
import { css, cx, keyframes } from '@emotion/css';
import { Handle, Position } from "reactflow";
import PlotlyChart from "./PlotlyChart";
import * as scheme from './Scheme'; // Import your styles s
import copy from 'copy-to-clipboard';
import { InnerData, VisibleTooltips } from "utils/type";
import { determineColorNew } from "utils/function";

interface ExxBoxFdirProp {
  data: {
    name: string;
    value: InnerData;
    exWidth: string;
    oriHeight: string;
  };
}

// Define the flashing animation
const flashingRed = keyframes`
  0% { background-color: red; }
  50% { background-color: orange; }
  100% { background-color: red; }
`;

const ExxBoxFdir: React.FC<ExxBoxFdirProp> = ({ data }) => {
  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});
  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});
  const [isContentVisible, setIsContentVisible] = useState(false); // State to toggle content visibility

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const handleParentClick = () => {
    if (!isContentVisible) {
      setIsContentVisible(true);
    }
  };

  const handleCollapseClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the parent click
    setIsContentVisible(false);
  };

  // Check if any telemetry value equals "1" to trigger the flashing effect
  const isFlashingRed = (data: InnerData) => {
    for (const innerData of Object.values(data)) {
      if (innerData?.dbData?.telemetry === '1') {
        return true;
      }
    }
    return false;
  };

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  // Define styles for the div
  const defaultStyle = css`
  ${isFlashingRed(data.value) ? `animation: ${flashingRed} 1s infinite;` : `background-color: gray;`}
  width: 450px;
    height: ${data.oriHeight ? data.oriHeight : '450px'};
    display: flex; 
    justify-content: center;  
    align-items: center;
    cursor: pointer; // To indicate the div is clickable
    transition: all 0.3s ease-in-out; // Add transition for smooth effect
  `;

  const clickedStyle = css`
  background-color: rgb(0, 0, 0); // Default background color
  width: ${data.exWidth ? data.exWidth : '450px'};
    cursor: pointer; // To indicate the div is clickable
    transition: all 0.3s ease-in-out; // Add transition for smooth effect
    border: 5px solid rgb(104, 176, 253);
    border: ${isFlashingRed(data.value) ? '5px solid red(104, 176, 253)' : '5px solid rgb(104, 176, 253)'};

  `;

  return (
    <div className={cx(isContentVisible ? clickedStyle : defaultStyle)} onClick={handleParentClick}>
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />

      <p onClick={handleCollapseClick}>
        {data.name} {isContentVisible && '«'}
      </p>
      {isContentVisible && (
        <div className={scheme.textboxItems}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key}>
              <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}

                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData?.live}`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }} />
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ExxBoxFdir;
