import { DataSourceApi } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import { useTimeRangeContext } from 'hooks/TimeRangeContext';
import React, { useEffect, useState, useCallback } from 'react';
import Plot from 'react-plotly.js';

interface Props {
  data: {
    mne: string;
    sc: string;
  };
}

interface PlotData {
  times: Date[];
  values: number[];
}

const LivePlot: React.FC<Props> = ({ data }) => {

  const { timeRange, guiValues } = useTimeRangeContext();

  // Use timeRange and guiValues as needed
  // console.log(timeRange);
  // console.log(guiValues); aefhka

  const [plotData, setPlotData] = useState<{ [key: string]: PlotData }>({});
  const [yAxisRange, setYAxisRange] = useState<[number, number]>([0, 0]);

  const fetchData = async (query: string, dataSourceName: string) => {
    const dataSource: DataSourceApi = await getDataSourceSrv().get(dataSourceName);
    //@ts-ignore
    const result = await dataSource.query({ targets: [{ query: query, refId: 'comQ' }] }).toPromise();
    return result;
  };

  const fetchAllData = useCallback(async () => {
    if (!timeRange) {
      return;
    }

    let allPlotData: { [key: string]: PlotData } = {};
    let allValues: number[] = [];

    //    |> range(start: ${timeRange.from.toISOString()}, stop: ${timeRange.to.toISOString()})

    let exQuery = `from(bucket: "telemetry")
    |> range(start: -30m)
    |> filter(fn: (r) =>
      r._measurement == "telemetry" and 
      r.facility == "${data.sc}" and
      r["_field"] == "cnv_value" and
      r.mne == "${data.mne}"
    )`;

  // Add aggregation if Agg is enabled
  if (guiValues.current.Agg) {
    exQuery += `
    |> aggregateWindow(
      every: ${guiValues.current.aggTime},
      fn: ${guiValues.current.aggFn.fn},
      createEmpty: false
    )`;
  }

  exQuery += `\n|> sort(columns: ["_time"], desc: true)`;

  // Add Last option if it's enabled
  if (guiValues.current.Last) {
    exQuery += `\n|> limit(n: ${guiValues.current.lastCount})`;
  }

    const result = await fetchData(exQuery, 'INFLUX');
    if (result.data && result.data[0] && result.data[0].fields) {
      const queryData = result.data[0].fields;
      //console.log(result.data);
      const times = queryData[0] ? queryData[0].values.map((date: string) => new Date(date)) : [];
      const values = queryData[1] ? queryData[1].values : [];
      allPlotData[data.mne] = { times, values };
      allValues = allValues.concat(values);
    }

    setPlotData(allPlotData);
    
    const minY = Math.min(...allValues);
    const maxY = Math.max(...allValues);
    const yPadding = (maxY - minY) * 1; // 20% padding
    setYAxisRange([minY - yPadding, maxY + yPadding]);
  }, [timeRange, data.mne, data.sc, guiValues.current.Last, guiValues.current.lastCount, guiValues.current.Agg, guiValues.current.aggTime, guiValues.current.aggFn]);

  useEffect(() => {
    fetchAllData(); // Initial fetch

    const interval = setInterval(() => {
      fetchAllData();
    }, 5000); // Fetch every 5 seconds

    return () => clearInterval(interval); // Clean up on unmount
  }, [fetchAllData]);

  return (
    <div>
      <Plot
        data={Object.keys(plotData).map(mne => ({
          x: plotData[mne].times,
          y: plotData[mne].values,
          type: 'scatter',
          mode: 'lines+markers',
          line: { color: 'white' },
          marker: { color: 'rgb(68, 169, 241)', size: 8 },
        }))}
        layout={{
          width: 800,
          height: 650,
          paper_bgcolor: 'black',
          plot_bgcolor: 'black',
          font: {
            color: 'white',
            size: 30,
          },
          yaxis: {
            range: yAxisRange,
          },
          xaxis: {
            type: 'date',
            tickformat: "%H:%M", // Show only the time on x-axis
          },
          title: {
            text: data.mne.includes('volt') ? 'Bim Voltage' : 'Bim Current',
            xanchor: 'center',
            yanchor: 'top',
          },
          margin: {
            t: 5,
            r: 5,
            b: 85,
            l: 95,
          },
          //showlegend: true,
          // legend: {
          //   x: 0.5,
          //   xanchor: 'center',
          //   y: 1,
          //   title: {
          //     text: 'MNE',
          //   },
          // },
          dragmode: false,
        }}
        config={{
          displayModeBar: false,
        }}
      />
    </div>
  );
};

export default LivePlot;
