import React from 'react'
import { InnerData, TLM } from 'utils/type';

interface ScSketchProp{
  data: {
    name: string;
    value: InnerData;
    select: TLM;
  }
}


const ScSketch: React.FC<ScSketchProp> = ({data}) => {

    const getColor = (name: string) => {
        if (data && data.select && data.select.telemetry){
          if (data.select.telemetry.toLowerCase().includes(name.toLowerCase())) {
            return "green"; // Green for the selected and ON disk
          }
          else{
            return "#404040";
          }
        }else{
          return "#404040";
        }
      }

  return (
    <div>

        <div style={{
            display: 'flex',
            flexDirection: 'column',
        }}>

          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="400px" height="360px" viewBox="-0.5 -0.5 400 360">
            <defs/>
              <g > {/* Rotate the whole SVG transform="rotate(45 150 150)" */}
                <g>
                  <rect x="5" y="50" width="390" height="300" fill="#606060" stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                </g>
                <g>
                  <rect x="5" y="5" width="90" height="90" fill={getColor('MGA')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="10" y="47" fill="white" fontSize="45px">MGA</text>
                  <text x="25" y="82" fill="white" fontSize="45px">Tx</text>
                </g>
                <g>
                  <rect x="305" y="5" width="90" height="90" fill={getColor('MGA')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="310" y="47" fill="white" fontSize="45px">MGA</text>
                  <text x="325" y="82" fill="white" fontSize="45px">Rx</text>
                </g>
                <g>
                  <rect x="35" y="95" width="60" height="60" fill={getColor('lga1')} stroke="rgb(13,39,45)" strokeWidth="5" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="40" y="125" fill="white" fontSize="25px">LGA</text>
                  <text x="40" y="150" fill="white" fontSize="25px">1Tx</text>
                </g>
                <g>
                  <rect x="305" y="95" width="60" height="60" fill={getColor('lga1')} stroke="rgb(13,39,45)" strokeWidth="5" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="310" y="125" fill="white" fontSize="25px">LGA</text>
                  <text x="310" y="150" fill="white" fontSize="25px">1Rx</text>
                </g>
                <g>
                    <circle cx="200" cy="95" r="90" fill={getColor('HGA')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                    <text x="160" y="110" fill="white" fontSize="45px">HGA</text>
                </g>
              
            </g>

            <text x="145" y="290" fill="white" fontSize="45px">+ Z</text>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="400px" height="360px" viewBox="-0.5 -0.5 400 360">
            <defs/>

              <g > {/* Rotate the whole SVG transform="rotate(45 150 150)" */}
              <g>
                    <circle cx="200" cy="95" r="90" fill={getColor('hga')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                </g>

                <g>
                  <rect x="5" y="5" width="90" height="90" fill={getColor('mga')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>

                </g>
                <g>
                  <rect x="305" y="5" width="90" height="90" fill={getColor('mga')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>

                </g>
                <g>
                  <rect x="5" y="50" width="390" height="300" fill="#606060" stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                </g>
                <g>
                  <rect x="35" y="95" width="60" height="60" fill={getColor('lga2')} stroke="rgb(13,39,45)" strokeWidth="5" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="40" y="125" fill="white" fontSize="25px">LGA</text>
                  <text x="40" y="150" fill="white" fontSize="25px">2Tx</text>
                </g>
                <g>
                  <rect x="305" y="95" width="60" height="60" fill={getColor('lga2')} stroke="rgb(13,39,45)" strokeWidth="5" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="310" y="125" fill="white" fontSize="25px">LGA</text>
                  <text x="310" y="150" fill="white" fontSize="25px">2Rx</text>
                </g>

              
            </g>

            <text x="145" y="290" fill="white" fontSize="45px">- Z</text>
            </svg>
                  </div>

    </div>
  );
}

export default ScSketch;
