import { css } from '@emotion/css';
import React from 'react';
import { useTimer } from 'react-timer-hook';

interface TimerProps {
  initialTime: number | null; // Time in seconds
  isVisible?: boolean; // New prop to control visibility
}

const Timer: React.FC<TimerProps> = ({ initialTime, isVisible=true }) => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + (initialTime || 0));

  const {
    days,
    hours,
    minutes,
    seconds,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp: time});

  const color = 'red';

  const TimeUnit: React.FC<{ label: string; value: number }> = ({ label, value }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ fontSize: '0.7em', color: 'white' }}>{label}</div>
      <div style={{ fontSize: '1.7em', color }}>{String(value).padStart(2, '0')}</div>
    </div>
  );

  const buttonStyle = css`
  padding: 10px;
  font-size: 16px;
  background-color: #696969;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #858585
  }
`

  if (!isVisible){
    return null;
  }

  return (
    <div className={css`
    display: flex;
    flex-direction: column;
    width: 750px;
    `}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: '#000000', 
          height: '200px',
        }}
      >
        <TimeUnit label="Days" value={days} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TimeUnit label="Hours" value={hours} />
          <div style={{ fontSize: '1.7em', color, alignSelf: 'flex-end' }}>:</div>
          <TimeUnit label="Minutes" value={minutes} />
          <div style={{ fontSize: '1.7em', color, alignSelf: 'flex-end' }}>:</div>
          <TimeUnit label="Seconds" value={seconds} />
        </div>
      </div>

      <div className={css`
display: grid;
grid-template-columns: repeat(3, 1fr);
      `}>
      <button onClick={pause} className={buttonStyle}>Pause</button>
        <button onClick={resume} className={buttonStyle}>Resume</button>
        <button onClick={() => {
          // Restarts to 5 minutes timer
          restart(time)
        }}  className={buttonStyle}>Restart</button>
      </div>
    </div>
  );
};

export default Timer;

