import { css } from '@emotion/css';

export const FrliteBox = css`
width: 1520px;
height: 500px;

  & > div {
    width:1520px;
    height: 450px;
    flex-direction: column;
    flex-wrap: wrap;

    & > div {
      width: 510px;

      &:nth-of-type(n+8):nth-of-type(-n+14) {
        width: 480px;

        & div:nth-of-type(2) {
          width: 200px;
        }
      }

      & div:nth-of-type(2) {
        width: 170px;
      }
    }
  }
`;

// Define a type for the class keyss
export type ClassKey = 'FrliteBox';