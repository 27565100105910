import React from 'react'
import { css } from '@emotion/css';

interface PanelProp {
  data: {
    source: string;
    name: string;
    width: number;
    height: number;
    hlink?: string;
  };
}

const Panel: React.FC<PanelProp> = ({data}) => {

  let color;

  if (data.source.includes('escg')) {
    color = 'gold';
  }else if (data.source.includes('escb')) {
    color = 'rgb(13, 57, 152)';
  }else{
    color = 'gray';
  }

  const panelStyle = css`
  width: ${data.width}px;
  height: ${data.height}px;
    border: 6px solid ${color};
    background-color: rgb(38, 38, 38);
  `;

  const linkStyle = css`
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `;

  const titleClass = css`
    font-size: 50px;
  `;

  return (
    <div className={panelStyle}>
      {data.hlink ? (
        <a href={data.hlink} className={linkStyle} target="_blank" rel="noopener noreferrer">
          <p className={titleClass}>{data.name} &raquo;</p>
        </a>
      ) : (
        <p className={titleClass}>{data.name} </p>
      )}
    </div>
  )
}

export default Panel;
