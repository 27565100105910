import React, {useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider, Node} from 'reactflow';
import 'reactflow/dist/style.css';
import Panel from 'module/Panel';
import { css } from '@emotion/css';
import Spoc from 'module/Spoc';
import Box from 'module/Box';
import VaryAntennas from './VaryAntennas';
import Prop from '../module/Prop';
import RCS from '../module/RCS';
import Antennas from './Antennas';
import ExxBox from './ExxBox';
import ExxCir from './ExxCir';
import PropThruster from './PropThruster';
import RCSThruster from './RCSThruster';
import LivePlot from './LivePlot';
import Time3dFacade from './TimeFacade';
import MemoryBox from './MemoryBox';
import VModeBox from './VModeBox';
import ExxBoxFdir from './ExxBoxFdir';
import ExxBoxFdirLarge from './ExxBoxFdirLarge';
import ScSketch from './ScSketch';
import { Field, LocationData, ProcessedData, TelemetryDictionary } from 'utils/type';
import PassIndicator from './PassIndicator';
import { createNodes } from 'utils/createNode';
import { createTelemetryDictionary, getGroundData } from 'utils/dataProcessFunc';
import { useTimeRangeContext } from 'hooks/TimeRangeContext';
import DrawerComponent from './Drawer';
import { useDrawer } from 'hooks/DrawerContext';
import Text from './Text';

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  groundData: Field[];
  locationData: LocationData;
  influxData: ProcessedData;
  limitData: Field[]
  // ... other props if there are any
};

const nodeTypes = {panel:Panel,vmodeBox:VModeBox,passIndicator:PassIndicator,scSketch:ScSketch,exxFdirBoxLarge:ExxBoxFdirLarge,exxFdirBox:ExxBoxFdir, memoryBox:MemoryBox,liveplot:LivePlot,rcsThruster:RCSThruster,timebox: Time3dFacade,propThruster:PropThruster,exxCir:ExxCir,exxbox:ExxBox,antennas:Antennas,prop:Prop, rcs:RCS,varyAntennas:VaryAntennas,box:Box,spoc:Spoc, text:Text}

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-panel, .react-flow__node-rcsPanel, .react-flow__node-spoc{
  z-index: 5 !important;
}

.react-flow__node-solarCell{
  z-index: 6 !important;

}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
  display: flex;
`;

const App: React.FC<AppProps> = ({dbData, width, height, groundData, locationData, influxData, limitData}) => {
  
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();

  const { isDrawerOpen, onClose } = useDrawer();

  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      reactFlowInstance.fitView();
    }
  }
  ,[width, height, reactFlowInstance]);

  const groundList = useMemo(() => getGroundData(groundData), [groundData]);

  const telemetryDictionary = useMemo(() => {
    return createTelemetryDictionary(dbData, limitData);
  }, [dbData, limitData]);

  const initialNodes = useMemo(() => {
    return createNodes(telemetryDictionary, locationData, groundList, pbTimeRef.current, pbLoopRef.current);
  }, [telemetryDictionary, locationData, groundList]);
 
  const [nodes, setNodes] = useState<Node[]>(initialNodes);

  const keyMnePairs = useMemo(() => {
    const pairs: Record<string, string> = {};
    
    nodes.forEach(node => {
      if (node.data.value) {
        Object.entries(node.data.value).forEach(([key, data]) => {
          // @ts-ignore
          pairs[key] = data.dbData?.mne || '';
        });
      }
    });
    
    return pairs;
  }, [nodes]);

  useEffect(() => {
    let isCancelled = false;

    if (guiValues.current.pbisInPlayBack) {
      const processData = async () => {
        let aggregatedData: TelemetryDictionary = {};
        const totalLoops = Object.keys(influxData).length;

        for (let i = 0; i < totalLoops; i++) {
          if (!guiValues.current.pbisInPlayBack || isCancelled) {
            break;
          }

          const key = Object.keys(influxData)[i];
          const selectedData = influxData[key];
          pbTimeRef.current = key;
          const newValue = (i + 1) / totalLoops;
          pbLoopRef.current = newValue;

          // Update aggregatedData with new values
          selectedData.forEach(item => {
            if (aggregatedData[item.name]) {
              if (item.cnvValue !== '') {
                aggregatedData[item.name].telemetry = item.cnvValue;
              }
            } else {
              aggregatedData[item.name] = {
                telemetry: item.cnvValue,
                mne: item.mne,
                limit: '',
                unit: '',
                spacecraft: '',
                live: 'rgb(68, 169, 241)',
              };
            }
          });

          // Create nodes from the aggregatedData object
          const newNodes = createNodes(
            aggregatedData,
            locationData,
            groundList,
            pbTimeRef.current,
            pbLoopRef.current,            
          );
          setNodes(newNodes);

          await new Promise(resolve => setTimeout(resolve, 2000));
        }
        guiValues.current.pbstopPlay();
      };

      processData();

      // Cleanup function to cancel the loop if pbisInPlayBack changes
      return () => {
        isCancelled = true;
      };
    } else {
      // When not in playback mode, update nodes with live data
      const newNodes = createNodes(
        telemetryDictionary,
        locationData,
        groundList,
        pbTimeRef.current,
        pbLoopRef.current,
      );
      setNodes(newNodes);
    }
  }, [
    guiValues.current.pbisInPlayBack,
    createNodes,
    influxData,
    telemetryDictionary,
    locationData,
    groundList,
    guiValues,
    createNodes
  ]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};
  
  const initialEdges = [
    {id: 'edge1', source: 'SolarArrsadfaay',sourceHandle: 'source-left', target: 'BCR',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow},

 ]; 

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height: height, width: width }}>
          <ReactFlow          
          edges={initialEdges}
          onInit={onInit}
          nodes={nodes}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: option.X, y: option.Y, zoom: option.Zoom}}
          minZoom={0.1}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true} 
          zoomOnDoubleClick={true}
          fitView={true}
          />
        </div>
        <DrawerComponent isOpen={isDrawerOpen} onClose={onClose} keyData={keyMnePairs} />
  
    </ReactFlowProvider>
  );

}

export default App;
